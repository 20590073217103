import React from "react";
import { HiArrowUpRight } from "react-icons/hi2";

export default function ProjectsCard({ image, title, service, link, tagline }) {
  return (
    <div className="card space-y-4">
      <div className="img-wrap">
        <img src={image} alt="NFTng" className="border" />
      </div>
      <div className="flex justify-between gap-5 items-center">
        <h1 className="text-lg lg:text-2xl font-bold">{title}</h1>{" "}
        <a href={link} target="_blank">
          <button className="text-pry bg-secondary rounded-full text-xs lg:text-sm flex items-center whitespace-nowrap gap-1 p-2 px-4">
            View project <HiArrowUpRight />
          </button>
        </a>
      </div>
      <div className="wrap flex gap-5">
        {service.map((i, index) => (
          <span
            className="text-xs rounded-full p-2 px-3 whitespace-nowrap bg-pry bg-opacity-60 font-bold"
            key={index}
          >
            {i}
          </span>
        ))}
      </div>
      <small className="font-semibold ml-2 mt-2">{tagline}</small>
    </div>
  );
}
